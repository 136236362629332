import React, { useContext, useEffect }  from "react";
import { signIn } from "../scripts/api-client";
import SEO from "../components/seo"
import LogoSVG from '../assets/svgs/logo.svg'
import {
  GlobalDispatchContext
} from "../context/GlobalContextProvider"

import { Row, Col, Layout, Card, Button } from 'antd';
const { Content } = Layout;

const IndexPage = ({data}) => {
  const dispatch = useContext(GlobalDispatchContext)

  useEffect(() => {
      dispatch({ type:'ROUTE', route: "/login"})
      dispatch({ type:'AUTH', auth_state: false, loading: false });
  }, [dispatch])
  return(
    <Content className="center" style={{width: "100vw", height: "100vh"}}>
      <SEO title="Login" />
      <div className="site-card-border-less-wrapper center">
        <Card cover={<LogoSVG style={{padding: "60px"}}/>} style={{ width: 450 }}>
        <Row gutter={[16, 24]} justify="center">
          <Col span={8}>
            <Button type="primary" size={"large"} block onClick={signIn}>Log In</Button>
          </Col>
        </Row>
        </Card>
      </div>
    </Content>
  )
}
export default IndexPage;
